<template>
    <v-container class="article">
        <v-row>
            <v-col cols="12" class="text-center secondary-font px-0">
                <v-card class="rounded-lg clickable">
                    <v-img
                        :src="getImage()"
                        class="white--text align-end"
                        min-height="350px"
                        max-height="550px"
                        contain
                    >
                        <v-card-title class="custom-orange text-h6" style="text-align: justify; word-break: break-word;" v-text="article.title" />
                    </v-img>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="white rounded-lg" justify="center" align="center">
            <v-col cols="12" style="font-size: 18px;" align="center" class="py-10">
                <span style="font-family: Ubuntu, Arial, sans-serif !important; text-align: center;" v-html="article.content" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import momentTimezone from 'moment-timezone'
    import articleService from '@/services/article'
    export default {
        data() {
            return {
                article: null
            }
        },
        watch: {
            '$route.params.id': {
                handler() {
                    this.getArticle()
                }
            }
        },
        created() {
            this.getArticle()
        },
        methods: {
            getImage() {
                return `${process.env.VUE_APP_GOOGLE_STORAGE_API}/${this.article.image}`
            },
            getDate() {
                return momentTimezone(this.article.date).tz('Europe/Athens').locale('el-GR').format('d/M/y, hh:mm').toString()
            },
            getArticle() {
                articleService.getArticle(this.$route.params.id).then(resp => {
                    this.article = resp.data
                })
            }
        }
    }
</script>
